import { RefObject } from 'react';
import { InputRef } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { BOOLEAN, DICTIONARY, STRING } from '../../../constants/common';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';
import { numberToInputString } from '../../../helpers/numberToInputString';
import { sortDictionaryColumn } from '../../../helpers/sort';
import { DictionaryModel } from '../../../models/DictionaryModel';
import { RecordModel } from '../../../models/DictionaryModel';

export const getColumns = (selectedDict: DictionaryModel, ref: RefObject<InputRef>) => {
    const sortedDict = sortDictionaryColumn(selectedDict);

    const defaultColumn: ColumnsType<RecordModel> = [
        {
            title: '№ з/п',
            key: 'index',
            ellipsis: true,
            children: [
                {
                    title: <div className="dict-table__header_children">1</div>,
                    key: 'index',
                    dataIndex: 'index',
                    width: 50,
                },
            ],
        },
    ];

    const customColumn: ColumnsType<RecordModel> = (sortedDict || []).map((column, index) => {
        const title = column.description;
        const ellipsis = column.colOrmName !== 'cyrillic';
        const key = column.colOrmName;
        const childrenTitle = <div className="dict-table__header_children">{column.columnNumber ?? index + 2}</div>;
        const childrenKey = column.colOrmName;
        const childrenRender = (cell: RecordModel) => (
            <span>
                {(() => {
                    if (column.refDictName?.includes('dict_rules_')) {
                        if (column.colOrmName === 'rule') return cell.rule?.code;
                        if (column.colOrmName === 'description') return cell.rule?.description;
                    }
                    switch (column.type?.code) {
                        case BOOLEAN:
                            return cell[column.colOrmName] === 1 ? 'Так' : '';
                        case DICTIONARY:
                            return cell[column.colOrmName]?.description || cell[column.colOrmName]?.code;
                        default:
                            return cell.fraction !== undefined && key === 'value'
                                ? numberToInputString(cell[column.colOrmName], cell.fraction)
                                : cell[column.colOrmName];
                    }
                })()}
            </span>
        );

        let sorterObj, filtersObj, childrenWidth;

        if (['code', 'rule'].includes(column.colOrmName)) {
            childrenWidth = 75;
            sorterObj = {
                sorter: true,
                defaultSortOrder: 'ascend',
            };
        }

        switch (column.type.code) {
            case BOOLEAN:
                filtersObj = {
                    filters: [
                        { text: 'Так', value: 1 },
                        { text: 'Пусто', value: '' },
                    ],
                };

                childrenWidth = 75;
                break;
            case DICTIONARY:
                if (column.refDictName?.includes('dict_rules_') && column.colOrmName === 'description') {
                } else {
                    childrenWidth = 75;
                }
                filtersObj = {
                    ...(getColumnSearchProps(ref, STRING) as RecordModel),
                };
                break;
            default:
                filtersObj = {
                    ...(getColumnSearchProps(ref, STRING) as RecordModel),
                };
                break;
        }

        return {
            title,
            key,
            ellipsis,
            children: [
                {
                    title: childrenTitle,
                    key: childrenKey,
                    render: childrenRender,
                    width: childrenWidth,
                    ...sorterObj,
                },
            ],
            ...filtersObj,
        };
    });

    return [...defaultColumn, ...customColumn];
};
