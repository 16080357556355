import { Form, Input, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { Rule } from 'rc-field-form/lib/interface';
import DictionarySelect from './components/DictionarySelect';
import MaskedInputNumber from './components/MaskedInputNumber';
import { BOOLEAN, DICTIONARY, DISABLED_WHITESPACE, NUMBER } from '../../../constants/common';
import { sortDictionaryColumn } from '../../../helpers/sort';
import { ColumnModel, DictionaryModel } from '../../../models/DictionaryModel';

const booleanList = [
    {
        value: 1,
        label: 'Так',
    },
    {
        value: '',
        label: 'Пусто',
    },
];

export const DictionaryRecordFields = (isUpdate: boolean, dict?: DictionaryModel, fraction?: number) => {
    const sortedDict = sortDictionaryColumn(dict);

    return sortedDict?.map((column: ColumnModel) => {
        if (column.refDictName?.includes('dict_rules_') && column.colOrmName === 'description') return null;
        const rules: Rule[] = [{ required: !!column.required }];
        if (!(column.type?.code === BOOLEAN || column.type?.code === NUMBER))
            rules.push({ whitespace: true, message: DISABLED_WHITESPACE });

        return (
            <Form.Item
                key={column.id}
                name={column.type?.code === DICTIONARY ? [column.colOrmName, 'code'] : column.colOrmName}
                label={column.description}
                rules={rules}
                initialValue={column.colOrmName === 'country' ? 'Україна' : undefined}>
                {(() => {
                    switch (column.type?.code) {
                        case NUMBER:
                            // NOTE: antD Form pass props to children, but it's not work with ts
                            // @ts-ignore
                            return <MaskedInputNumber column={column} fraction={fraction} />;
                        case BOOLEAN:
                            return <Select placeholder={column.description} options={booleanList} />;
                        case DICTIONARY:
                            // NOTE: antD Form pass props to children, but it's not work with ts
                            // @ts-ignore
                            return <DictionarySelect column={column} dict={dict} />;
                        default:
                            const defaultProps = {
                                disabled: column.colOrmName === 'code' && isUpdate,
                                placeholder: column.description,
                            };

                            if (column.regEx) {
                                return (
                                    <MaskedInput
                                        mask={[
                                            {
                                                mask: column.regEx,
                                                placeholderChar: ' ',
                                                lazy: false,
                                            },
                                        ]}
                                        {...defaultProps}
                                    />
                                );
                            }
                            return <Input {...defaultProps} />;
                    }
                })()}
            </Form.Item>
        );
    });
};
