import { useMemo } from 'react';
import { Button, Col, DatePicker, Dropdown, Row, Space, Spin, Table } from 'antd';
import dayjs from 'dayjs';
import { CaretDownOutlined, PrinterOutlined, LeftOutlined, RightOutlined, ReloadOutlined } from '@ant-design/icons';
import { getColumns } from './components/getColumns';
import Modal from '../../components/common/Modal';
import ReportModal from '../../components/modal/ReportModal';
import { DATA_PROCESSING_STAT_TABLE, DATE_FORMAT, DATE_FORMAT_FOR_QUERY } from '../../constants';
import { ReportTypes } from '../../constants/enums';
import { useHandleDatePickerWithArrows, useModal } from '../../hooks';
import { useHandleError } from '../../hooks/useHandleError';
import { DataProcessingStat } from '../../models/dataProcessingStat';
import { useGetSystemDictionaryQuery } from '../../redux/api/dictionaryApi';
import { useGetDataProcessingStatQuery } from '../../redux/api/systemApi';

const DataProcessingStats = () => {
    const [reportModal, openReportModal, closeReportModal] = useModal();
    const { data: dictKafkaTopics, isLoading: isLoadingDictKafkaTopics } = useGetSystemDictionaryQuery({
        dictionaryName: 'dict_kafka_topics',
    });

    const sizes = useMemo(() => {
        const tableSizeJson = window.localStorage.getItem(DATA_PROCESSING_STAT_TABLE);
        return tableSizeJson ? JSON.parse(tableSizeJson) : null;
    }, []);

    const { date, setDate, handlePrevDay, handleNextDay } = useHandleDatePickerWithArrows();
    const { data, error, isLoading, isFetching, refetch } = useGetDataProcessingStatQuery(date);

    useHandleError(error);

    return (
        <Spin spinning={isLoading || isFetching || isLoadingDictKafkaTopics}>
            <Modal
                onCancel={closeReportModal}
                destroyOnClose
                title={`Друк звіту "Статистика обробки даних"`}
                open={reportModal}>
                <ReportModal onSuccess={closeReportModal} reportType={ReportTypes.dataProcessingStat} />
            </Modal>
            <Row justify="space-between" className="marginBottom2">
                <Row gutter={8}>
                    <Col>
                        <Button onClick={handlePrevDay} icon={<LeftOutlined />} />
                    </Col>
                    <Col>
                        <DatePicker
                            value={dayjs(date)}
                            onChange={(date) => setDate(dayjs(date).format(DATE_FORMAT_FOR_QUERY))}
                            format={DATE_FORMAT}
                            allowClear={false}
                        />
                    </Col>
                    <Col>
                        <Button onClick={handleNextDay} icon={<RightOutlined />} />
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col>
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        key: '1',
                                        label: `Друк звіту "Статистика обробки даних"`,
                                        onClick: openReportModal,
                                    },
                                ],
                            }}>
                            <Button>
                                <Space>
                                    <PrinterOutlined />
                                    <CaretDownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </Col>
                    <Col>
                        <Button icon={<ReloadOutlined />} onClick={refetch} loading={isFetching} />
                    </Col>
                </Row>
            </Row>
            <Table<DataProcessingStat>
                rowKey={(record) => record.topic + record.changeNumber}
                size="small"
                scroll={{ x: 600 }}
                columns={getColumns(sizes, dictKafkaTopics)}
                dataSource={data}
                bordered
                pagination={false}
            />
        </Spin>
    );
};

export default DataProcessingStats;
