import { useRef, FC } from 'react';
import { DefaultOptionType } from 'antd/es/select';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import TableWrapper from './TableWrapper';
import { DATE_FORMAT } from '../../../../constants';
import { localeCompareSort, getColumnSearchProps } from '../../../../helpers';
import { selectOptions } from '../../../../helpers/selectOptionsSystemDict';
import { AlertCddMeasures, ExtendedAlert } from '../../../../models/Alert';
import { FormItemsWithInputType } from '../../../../models/Common';
import { useGetSystemDictionaryQuery } from '../../../../redux/api/dictionaryApi';
import { QueryType, useGetLazyQuery } from '../../hooks/useQuery';

interface CddMeasuresProps {
    alert: ExtendedAlert;
}

const formItemsProps = (
    alertId: ExtendedAlert['id'],
    dictOptions: DefaultOptionType[] = [],
): FormItemsWithInputType[] => [
    {
        name: ['alert', 'id'],
        initialValue: alertId,
        required: true,
        disabled: true,
        hidden: true,
    },
    {
        name: 'id',
        disabled: true,
        hidden: true,
    },
    {
        name: 'date',
        type: 'date',
        required: true,
        label: 'Дата',
    },
    {
        name: ['type', 'code'],
        type: 'select',
        required: true,
        options: dictOptions,
        label: 'Тип заходу',
    },
    {
        name: 'description',
        required: true,
        rules: [
            {
                max: 500,
            },
        ],
        label: 'Опис заходу',
        type: 'textArea',
    },
];

const CddMeasures: FC<CddMeasuresProps> = ({ alert }) => {
    const { triggerFetch, data } = useGetLazyQuery();
    const { data: dict } = useGetSystemDictionaryQuery({ dictionaryName: 'dict_cdd_measures' });
    const dictOptions = selectOptions(dict).map((item) => ({
        ...item,
        disabled: data.cddMeasures?.some((measure) => measure.type?.code === item.value),
    }));
    const inputRef = useRef(null);

    const columns: ColumnsType<AlertCddMeasures> = [
        {
            key: 'date',
            dataIndex: 'date',
            title: 'Дата',
            sorter: (a, b) => localeCompareSort(a, b, 'date'),
            ...getColumnSearchProps<AlertCddMeasures>(inputRef, 'date', 'date'),
            ellipsis: true,
            width: 60,
            render: (date) => dayjs(date).format(DATE_FORMAT),
        },
        {
            key: ['type', 'description'].join('/'),
            dataIndex: ['type', 'description'],
            title: 'Тип заходу',
            sorter: (a, b) => localeCompareSort(a, b, 'description'),
            ...getColumnSearchProps<AlertCddMeasures>(inputRef, 'string', ['type', 'description']),
            ellipsis: true,
            width: 120,
        },
        {
            key: 'description',
            dataIndex: 'description',
            title: 'Опис заходу',
            sorter: (a, b) => localeCompareSort(a, b, 'description'),
            ...getColumnSearchProps<AlertCddMeasures>(inputRef, 'string', 'description'),
            ellipsis: true,
            width: 400,
        },
    ];
    return (
        <TableWrapper
            rowKey="id"
            dataSource={data.cddMeasures || alert?.cddMeasures}
            columns={columns}
            isActionBar={true}
            isRowSelection={true}
            isEditable={true}
            isClearFilters={true}
            formItemsProps={formItemsProps(alert?.id, dictOptions)}
            queryType={QueryType.cddMeasures}
            alertStatus={alert?.status?.code}
            triggerFetch={() => triggerFetch(QueryType.cddMeasures, alert?.id)}
            scrollable={false}
            alertId={alert?.id}
        />
    );
};

export default CddMeasures;
