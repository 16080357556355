import React, { FC, useEffect, useMemo } from 'react';
import { Form, FormInstance, Tabs } from 'antd';
import { Option } from './Card';
import EditableTab from './EditableTab';
import { ALLOWED_CODES_FOR_PROCESSED, STATUS_CODE_PROCESSED } from '../../../../constants/Alert';
import { RULES_TYPE_CRITERION, RULES_TYPE_INDICATOR } from '../../../../constants/Rules';
import { filterRules } from '../../../../helpers/filterRules';
import {
    AlertTabRowData,
    getGeneralData,
    getRequisitesData,
    getProfileData,
    getAdditionalData,
    getCommentsData,
    getConclusionData,
    getApprovalData,
    getMeasuresData,
} from '../../../../helpers/getAlertData';
import { AlertAdditionalInfo, ExtendedAlert } from '../../../../models/Alert';
import { RecordModel } from '../../../../models/DictionaryModel';
import { RuleModel } from '../../../../models/RulesModel';
import { UserModel } from '../../../../models/UserModel';
import { useGetAdditionalInfoQuery } from '../../../../redux/api/alertsApi';
import { getUser } from '../../../../redux/features/authSlice';
import { useAppSelector } from '../../../../redux/hooks';
import { useGetRulesLists } from '../../hooks/useGetRulesLists';
import { useTotalRiskScore } from '../../hooks/useTotalRiskScore';
import { useWatchAllFields } from '../../hooks/useWatchAllFields';
import { useWatchIsDocumentProvided } from '../../hooks/useWatchIsDocumentProvided';

interface AlertTabClientInfo {
    label: string;
    key: string;
    data: AlertTabRowData[];
    forceRender?: boolean;
}
const getTab = (
    tabs: AlertTabClientInfo[],
    errorKeyList: Array<string | number>,
    onSave: () => void,
    user?: UserModel | null,
    statusCode: string = '',
    controllerId?: number,
    alert?: ExtendedAlert,
) => {
    const disabled =
        (!!user?.rolesParams?.IS_ANALYST && !ALLOWED_CODES_FOR_PROCESSED.includes(statusCode)) ||
        (!!user?.rolesParams?.IS_CONTROLLER && statusCode !== STATUS_CODE_PROCESSED) ||
        (!!user?.rolesParams?.IS_CONTROLLER_2ND && controllerId !== user.id);
    return tabs.map((tab) => {
        const tabRowsNames = tab.data.map((row) => row.key);
        const hasError = tabRowsNames.some((name) => errorKeyList.includes(name));
        return {
            label: (
                <>
                    {tab.label}
                    <div
                        className="error-marker"
                        style={{
                            backgroundColor: `${hasError ? 'red' : 'transparent'}`,
                        }}
                    />
                </>
            ),
            key: tab.key,
            children: <EditableTab items={tab.data} disabled={disabled} onBlur={onSave} alert={alert} />,
            forceRender: tab.forceRender ?? true,
        };
    });
};

const getTabInfo = (
    model: ExtendedAlert,
    indicators: Option[],
    criteria: Option[],
    totalRiskScore: number,
    availableManual: Option[],
    availableAdditional: Option[],
    rolesParams: UserModel['rolesParams'] | undefined,
    solution: RecordModel[] | undefined,
    minRisksMeasures: RecordModel[] | undefined,
    form: FormInstance<any>,
    additionalInfo: AlertAdditionalInfo | undefined,
    isAdditionalInfoLoading: boolean,
    availableManualIndicators: Option[],
    availableAdditionalIndicators: Option[],
    branches: RecordModel[] | undefined,
): AlertTabClientInfo[] => [
    {
        label: 'Загальна інформація',
        key: 'Загальна інформація',
        data: getGeneralData(model, additionalInfo, isAdditionalInfoLoading),
    },
    {
        label: 'Основні дані',
        key: 'Основні дані',
        data: getRequisitesData(model, additionalInfo, isAdditionalInfoLoading, form),
    },
    {
        label: 'Профіль клієнта',
        key: 'Профіль клієнта',
        data: getProfileData(model),
    },
    {
        label: 'Філія та запит',
        key: 'Філія та запит',
        data: getAdditionalData(model, form, branches),
    },
    {
        label: 'Аналіз',
        key: 'Аналіз',
        data: getCommentsData(model, indicators, criteria, minRisksMeasures, form),
    },
    {
        label: 'Висновок і пропозиції',
        key: 'Висновок і пропозиції',
        data: getConclusionData(
            model,
            availableManual,
            availableAdditional,
            solution,
            totalRiskScore,
            form,
            availableManualIndicators,
            availableAdditionalIndicators,
        ),
    },
    {
        label: 'Заходи НПК',
        key: 'Заходи НПК',
        data: getMeasuresData(),
        forceRender: false,
    },
    {
        label: 'Погодження',
        key: 'Погодження',
        data: getApprovalData(model, rolesParams),
    },
];

interface ClientInfoProps {
    model: ExtendedAlert;
    errorKeyList: Array<string | number>;
    rules: RuleModel[];
    solution?: RecordModel[];
    onSave: () => void;
    minRisksMeasures?: RecordModel[];
    branches?: RecordModel[];
}

const ClientInfo: FC<ClientInfoProps> = ({
    model,
    errorKeyList,
    rules,
    solution,
    onSave,
    minRisksMeasures,
    branches,
}) => {
    const user = useAppSelector(getUser);
    const form = Form.useFormInstance();
    const {
        manuallyAddedCriteriaCodes,
        approvedCriteriaCodes,
        declinedCriteriaCodes,
        additionalCriteriaCodes,
        manuallyAddedIndicatorCodes,
        approvedIndicatorsCodes,
        declinedIndicatorsCodes,
    } = useWatchAllFields();
    const indicators = useMemo(() => filterRules(rules, RULES_TYPE_INDICATOR), [rules]);
    const criteria = useMemo(() => filterRules(rules, RULES_TYPE_CRITERION), [rules]);
    const {
        data: additionalInfo,
        isLoading: isAdditionalInfoLoading,
        isFetching: isAdditionalInfoFetching,
    } = useGetAdditionalInfoQuery(model.id);

    const autoAddedIndicatorCodes = useMemo(
        () =>
            model?.rules
                ?.filter((item) => item.rule?.type?.code === RULES_TYPE_INDICATOR && item.addedManually === 0)
                .map((item) => item.rule.id) ?? [],
        [model],
    );

    const autoAddedCriteriaCodes = useMemo(
        () =>
            model?.rules
                ?.filter((item) => item.rule?.type?.code === RULES_TYPE_CRITERION && item.addedManually === 0)
                .map((item) => item.rule.id) ?? [],
        [model],
    );

    const alertCriteriaCodes = useMemo(
        () =>
            manuallyAddedCriteriaCodes &&
            autoAddedCriteriaCodes && [...manuallyAddedCriteriaCodes, ...autoAddedCriteriaCodes],
        [autoAddedCriteriaCodes, manuallyAddedCriteriaCodes],
    );

    const alertIndicatorsCodes = useMemo(
        () =>
            manuallyAddedIndicatorCodes &&
            autoAddedIndicatorCodes && [...manuallyAddedIndicatorCodes, ...autoAddedIndicatorCodes],
        [autoAddedIndicatorCodes, manuallyAddedIndicatorCodes],
    );

    useWatchIsDocumentProvided(manuallyAddedCriteriaCodes, criteria, alertCriteriaCodes);

    const totalRiskScore = useTotalRiskScore(
        approvedCriteriaCodes,
        declinedCriteriaCodes,
        additionalCriteriaCodes,
        criteria,
        model.customer.isStateEnterprise,
    );

    const {
        listForManualSelectIndicators,
        listForManualSelectCriteria,
        availableManual,
        availableAdditional,
        availableManualIndicators,
        availableAdditionalIndicators,
    } = useGetRulesLists(
        indicators,
        criteria,
        autoAddedIndicatorCodes,
        autoAddedCriteriaCodes,
        alertCriteriaCodes,
        approvedCriteriaCodes,
        declinedCriteriaCodes,
        additionalCriteriaCodes,
        alertIndicatorsCodes,
    );

    useEffect(() => {
        if (declinedCriteriaCodes)
            form.setFieldValue(
                'approvedCriteria',
                alertCriteriaCodes?.filter((code) => !declinedCriteriaCodes?.includes(code)),
            );
    }, [alertCriteriaCodes, declinedCriteriaCodes, form]);

    useEffect(() => {
        if (approvedCriteriaCodes)
            form.setFieldValue(
                'declinedCriteria',
                alertCriteriaCodes?.filter((code) => !approvedCriteriaCodes?.includes(code)),
            );
        // disable eslint warning coz we don't need to update form on alertCriteriaCodes changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvedCriteriaCodes, declinedCriteriaCodes, form]);

    useEffect(() => {
        if (declinedIndicatorsCodes)
            form.setFieldValue(
                'approvedIndicators',
                alertIndicatorsCodes?.filter((code) => !declinedIndicatorsCodes?.includes(code)),
            );
    }, [alertIndicatorsCodes, declinedIndicatorsCodes, form]);

    useEffect(() => {
        if (approvedIndicatorsCodes)
            form.setFieldValue(
                'declinedIndicators',
                alertIndicatorsCodes?.filter((code) => !approvedIndicatorsCodes?.includes(code)),
            );
        // disable eslint warning coz we don't need to update form on alertIndicatorsCodes changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvedIndicatorsCodes, declinedCriteriaCodes, form]);

    const tabInfo = getTabInfo(
        model,
        listForManualSelectIndicators,
        listForManualSelectCriteria,
        totalRiskScore,
        availableManual,
        availableAdditional,
        user?.rolesParams,
        solution,
        minRisksMeasures,
        form,
        additionalInfo,
        isAdditionalInfoLoading || isAdditionalInfoFetching,
        availableManualIndicators,
        availableAdditionalIndicators,
        branches,
    );

    const items = getTab(tabInfo, errorKeyList, onSave, user, model?.status?.code, model?.controller?.id, model);

    return <Tabs destroyInactiveTabPane={true} size="small" type="card" items={items} />;
};

export default ClientInfo;
