import dayjs from 'dayjs';
import buildQuery, { QueryOptions } from 'odata-query';
import { createApi } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';
import { DATE_FORMAT_FOR_QUERY } from '../../constants';
import { getTopSkip } from '../../helpers';
import { Account } from '../../models/Account';
import {
    Accounts,
    AlertAdditionalInfo,
    AlertCddMeasures,
    BaseAlert,
    ExtendedAlert,
    ExtendedCustomer,
    Relation,
    Rule,
} from '../../models/Alert';
import { AlertHistory } from '../../models/AlertHistory';
import { ApiProtocol } from '../../models/ApiProtocol';
import { BlackList } from '../../models/BlackList';
import { AdditionalRequisit, Counterparties, Transaction } from '../../models/Transaction';

export enum Tags {
    alerts = 'Alerts',
    alert = 'Alert',
    alertHistory = 'AlertHistory',
    alertsCddMeasures = 'AlertsCddMeasures',
}
interface BaseResponse<T> {
    count: number;
    items: T[];
}

type BaseRequest<T> = Partial<QueryOptions<T>>;

type GetHistoryRequest = {
    id: number;
    queryData: BaseRequest<AlertHistory>;
};

export const alertsApi = createApi({
    reducerPath: 'alertsApi',
    baseQuery: customBaseQuery,
    tagTypes: [Tags.alerts, Tags.alert, Tags.alertHistory, Tags.alertsCddMeasures],
    endpoints: (builder) => ({
        getAlerts: builder.query<BaseResponse<BaseAlert>, BaseRequest<BaseAlert>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `client/alerts${queryString}`,
                    method: 'GET',
                };
            },
            providesTags: [Tags.alerts],
        }),
        getAlert: builder.query<ExtendedAlert, number>({
            query(id) {
                return {
                    url: `client/alerts/${id}`,
                    method: 'GET',
                };
            },
            providesTags: (_result, _error, id) => [{ type: Tags.alert, id }],
            transformResponse: (response: ExtendedAlert) => {
                // todo удалить после того как с бекенда будут приходить всегда уникальные сущности
                //  сейчас у нескольких сущностей может быть одинаковый код правила
                const filteredRules: Rule[] = [];
                response.rules?.forEach((model) => {
                    if (filteredRules.every((filtered) => filtered.rule.id !== model.rule.id)) {
                        filteredRules.push(model);
                    }
                });
                return response;
            },
        }),
        getAlertAccounts: builder.query<Account[], number>({
            query(id) {
                return {
                    url: `client/alerts/${id}/accounts`,
                    method: 'GET',
                };
            },
            transformResponse: (response: ApiProtocol) => response?.accounts ?? [],
        }),
        getAlertTransactions: builder.query<{ transactions: Transaction[]; counterparties: Counterparties[] }, number>({
            query(id) {
                return {
                    url: `client/alerts/${id}/transactions`,
                    method: 'GET',
                };
            },
            transformResponse: (response: ApiProtocol) => {
                response?.documents?.forEach((transaction: Transaction) => {
                    if (!transaction.dk && transaction.amount && transaction.amountEquivalent) {
                        transaction.amount = -transaction.amount;
                        transaction.amountEquivalent = -transaction.amountEquivalent;
                    }
                });
                return {
                    transactions: response?.documents ?? [],
                    counterparties: response?.counterparties ?? [],
                };
            },
        }),
        getClientAlert: builder.query<BaseAlert[], number>({
            query(id) {
                return {
                    url: `client/alerts/${id}/previous`,
                    method: 'GET',
                };
            },
        }),
        getAlertOtherRnk: builder.query<ExtendedCustomer[], number>({
            query(id) {
                return {
                    url: `client/alerts/${id}/accounts/other`,
                    method: 'GET',
                };
            },
        }),
        getAlertBlackList: builder.query<BlackList[], number>({
            query(id) {
                return {
                    url: `client/alerts/${id}/blacklist`,
                    method: 'GET',
                };
            },
        }),
        updateAlert: builder.mutation<BaseAlert, Partial<ExtendedAlert>>({
            query(data) {
                const { id, ...body } = data;
                return {
                    url: `client/alerts/${id}`,
                    method: 'PUT',
                    body: body,
                };
            },
            invalidatesTags: (_result, _error, arg) => [{ type: Tags.alert, id: arg.id }],
        }),
        updateAlertSilent: builder.mutation<ExtendedAlert, Partial<ExtendedAlert>>({
            query(data) {
                const { id, ...body } = data;
                return {
                    url: `client/alerts/${id}`,
                    method: 'PUT',
                    body: body,
                };
            },
            invalidatesTags: (_result, _error, arg) => [{ type: Tags.alertHistory, id: arg.id }],
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (id) dispatch(alertsApi.util.updateQueryData('getAlert', id, () => data));
                } catch {}
            },
        }),
        updateAnalysts: builder.mutation<any, any>({
            query(data) {
                return {
                    url: `client/alerts/analyst`,
                    method: 'PATCH',
                    body: data,
                };
            },
            invalidatesTags: (_result, _error, args) => [
                ...args.alerts.map((id: number) => ({ type: Tags.alert, id })),
                Tags.alerts,
            ],
        }),
        updateStatus: builder.mutation<any, any>({
            query(data) {
                const { skipUpdateCache, ...rest } = data;
                return {
                    url: `client/alerts/status`,
                    method: 'PATCH',
                    body: { ...rest },
                };
            },
            invalidatesTags: (_result, _error, args) => {
                if (args.skipUpdateCache) return [];
                return [...args.alerts.map((id: number) => ({ type: Tags.alert, id })), Tags.alerts];
            },
        }),
        updatePriority: builder.mutation<any, { ids: number[]; urgencyCode: string }>({
            query(data) {
                const ids = data.ids ?? [];
                return {
                    url: `client/alerts/urgency`,
                    method: 'PATCH',
                    body: { urgencyCode: data.urgencyCode, alerts: ids },
                };
            },
            invalidatesTags: (_result, _error, args) => [
                ...args.ids.map((id) => ({ type: Tags.alert, id })),
                Tags.alerts,
            ],
        }),
        updateDeadline: builder.mutation<any, { ids: number[]; date: string }>({
            query(data) {
                const ids = data.ids ?? [];
                return {
                    url: `client/alerts/deadline`,
                    method: 'PATCH',
                    body: { date: data.date, alerts: ids },
                };
            },
            invalidatesTags: (_result, _error, args) => [
                ...args.ids.map((id) => ({ type: Tags.alert, id })),
                Tags.alerts,
            ],
        }),
        getAlertHistory: builder.query<BaseResponse<AlertHistory>, GetHistoryRequest>({
            query({ id, queryData }) {
                const queryString = buildQuery(queryData);
                return {
                    url: `client/alerts/history/${id}${queryString}`,
                    method: 'GET',
                };
            },
            providesTags: (_result, _error, arg) => [{ type: Tags.alertHistory, id: arg.id }],
        }),
        getCustomerRelations: builder.query<Relation[], number>({
            query(id) {
                return {
                    url: `client/alerts/${id}/relations`,
                    method: 'GET',
                };
            },
        }),
        updateController: builder.mutation<any, any>({
            query(data) {
                const { skipUpdateCache, ...rest } = data;
                return {
                    url: `client/alerts/controller`,
                    method: 'PATCH',
                    body: { ...rest },
                };
            },
            invalidatesTags: (_result, _error, args) => {
                if (args.skipUpdateCache) return [];
                return [...args.alerts.map((id: number) => ({ type: Tags.alert, id })), Tags.alerts];
            },
        }),
        getAdditionalRequisites: builder.query<AdditionalRequisit[], { reference?: number; kf?: string }>({
            query(data) {
                return {
                    url: `client/transactions/additional-requisites?reference=${data.reference}&kf=${data.kf}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: ApiProtocol) => response?.requisites ?? [],
        }),
        getAdditionalInfo: builder.query<AlertAdditionalInfo, number>({
            query(id) {
                return {
                    url: `client/alerts/${id}/additional`,
                    method: 'GET',
                };
            },
        }),
        getTransactionsParams: builder.query<Transaction[], { ruleId: number; pagination: any }>({
            query(data) {
                const queryString = buildQuery(getTopSkip(data.pagination.pageSize, data.pagination.current));
                return {
                    url: `client/alerts/params-transactions/${data.ruleId}${queryString}`,
                    method: 'GET',
                };
            },
        }),
        getAccountsParams: builder.query<Accounts[], { ruleId: number; pagination: any }>({
            query(data) {
                const queryString = buildQuery(getTopSkip(data.pagination.pageSize, data.pagination.current));
                return {
                    url: `client/alerts/params-accounts/${data.ruleId}${queryString}`,
                    method: 'GET',
                };
            },
        }),
        getCddMeasures: builder.query<AlertCddMeasures[], number>({
            query(id) {
                return {
                    url: `client/alerts/${id}/cdd-measures`,
                    method: 'GET',
                };
            },
            providesTags: [Tags.alertsCddMeasures],
        }),
        addCddMeasures: builder.mutation<void, AlertCddMeasures>({
            query(data) {
                data.date = dayjs(data.date).format(DATE_FORMAT_FOR_QUERY);
                return {
                    url: `client/alerts/cdd-measures`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: [Tags.alertsCddMeasures],
        }),
        deleteCddMeasures: builder.mutation<void, any>({
            query(data) {
                return {
                    url: `client/alerts/cdd-measures`,
                    method: 'DELETE',
                    body: data,
                };
            },
            invalidatesTags: [Tags.alertsCddMeasures],
        }),
    }),
});

export const {
    useGetAlertsQuery,
    useGetAlertQuery,
    useGetAlertAccountsQuery,
    useLazyGetAlertAccountsQuery,
    useGetAlertTransactionsQuery,
    useLazyGetAlertTransactionsQuery,
    useGetClientAlertQuery,
    useLazyGetClientAlertQuery,
    useGetAlertOtherRnkQuery,
    useLazyGetAlertOtherRnkQuery,
    useGetAlertBlackListQuery,
    useLazyGetAlertBlackListQuery,
    useUpdateAlertMutation,
    useUpdateAlertSilentMutation,
    useUpdateAnalystsMutation,
    useUpdateStatusMutation,
    useUpdatePriorityMutation,
    useUpdateDeadlineMutation,
    useGetAlertHistoryQuery,
    useLazyGetCustomerRelationsQuery,
    useUpdateControllerMutation,
    useGetAdditionalRequisitesQuery,
    useGetAdditionalInfoQuery,
    useLazyGetTransactionsParamsQuery,
    useLazyGetAccountsParamsQuery,
    useLazyGetCddMeasuresQuery,
    useAddCddMeasuresMutation,
    useDeleteCddMeasuresMutation,
} = alertsApi;
