import { notification } from 'antd';
import { BASE_NOTIFICATION_CONFIG } from '../constants/common';
import { BASE_URL } from '../redux/api/common/customBaseQuery';

const saveAs = (blob: Blob, fileName: string = '') => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
};

const baseErrorMessage = 'Щось пішло не так, спробуйте зменшити обраний період';

export const reportFetch = async (
    reportType: string,
    params: string[] | null,
    query: string | null,
    onSuccess: (() => void) | null,
    body: Record<any, any> | null,
    token: string | undefined,
    uid: number | undefined,
) => {
    if (!token || !uid) {
        notification.info({
            ...BASE_NOTIFICATION_CONFIG,
            message: 'Ви не авторизовані',
        });
        return;
    }
    let fileName: string | undefined;
    const options: RequestInit = body
        ? {
              method: 'POST',
              headers: {
                  'content-Type': 'application/json',
              },
              body: JSON.stringify(body),
          }
        : {};

    options.headers = {
        ...options.headers,
        User: uid ? uid.toString() : '',
        Authorization: 'Bearer ' + token,
    };

    await fetch(
        BASE_URL + 'report/' + reportType + (params ? '/' + params.join('/') : '') + (query ? '?' + query : ''),
        options,
    )
        .then((response) => {
            if (response.ok) {
                fileName = response.headers.get('Content-Disposition')?.split('filename=')[1].split('"')[1];
                return response.blob();
            } else {
                return Promise.reject(response);
            }
        })
        .then((blob) => blob && saveAs(blob, fileName))
        .then(() => {
            onSuccess && onSuccess();
        })
        .catch(async (error: Response) => {
            let errMsg = '';
            try {
                errMsg = await error.json().then((data) => data.message);
            } catch {}
            if (errMsg.includes('ENOENT: no such file or directory')) {
                errMsg = 'Звіт тимчасово недоступний';
            }
            notification.info({
                ...BASE_NOTIFICATION_CONFIG,
                message: errMsg || baseErrorMessage,
            });
        });
};
