import React, { FC, useContext } from 'react';
import { Button, Modal, Row, Space, TableProps, message } from 'antd';
import { ClientContext } from './BankInfo/ClientContext';
import EditableTableModal from './components/EditableTableModal';
import CustomModal from '../../../../components/common/Modal';
import { ALLOWED_CODES_FOR_PROCESSED, STATUS_CODE_PROCESSED } from '../../../../constants';
import { ReportTypes } from '../../../../constants/enums';
import { reportFetch } from '../../../../helpers';
import { useModal } from '../../../../hooks';
import { StatusCodes } from '../../../../models/Catalog';
import { FormItemsWithInputType } from '../../../../models/Common';
import { alertsApi, Tags } from '../../../../redux/api/alertsApi';
import { getToken, getUser } from '../../../../redux/features/authSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { QueryType, useDeleteQuery } from '../../hooks/useQuery';

interface ActionsBarProps {
    clearFilters: false | (() => void);
    currentDataSource: TableProps<any>['dataSource'];
    reportType?: ReportTypes;
    isFiltersApplied?: boolean;
    isSorterApplied?: boolean;
    dto?: (dataSource: any) => Record<string, any>;
    isEditable?: boolean;
    formItemsProps?: FormItemsWithInputType[];
    selectedRows?: any[];
    clearSelection?: () => void;
    queryType?: QueryType;
    alertStatus?: StatusCodes;
    alertId?: number;
}

const ActionsBar: FC<ActionsBarProps> = ({
    clearFilters,
    currentDataSource,
    reportType,
    isFiltersApplied,
    isSorterApplied,
    dto,
    isEditable,
    formItemsProps,
    selectedRows,
    clearSelection,
    queryType,
    alertStatus,
    alertId,
}) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(getUser);
    const token = useAppSelector(getToken);
    const clientInfo = useContext(ClientContext);
    const [loading, setLoading] = React.useState(false);
    const [isModalOpen, openModal, closeModal] = useModal();
    const [isUpdate, setIsUpdate] = React.useState(false);
    const disabledActions =
        (!!user?.rolesParams?.IS_ANALYST && !ALLOWED_CODES_FOR_PROCESSED.includes(alertStatus || '')) ||
        ((!!user?.rolesParams?.IS_CONTROLLER ||
            !!user?.rolesParams?.IS_CONTROLLER_1ST ||
            !!user?.rolesParams?.IS_CONTROLLER_2ND) &&
            alertStatus !== STATUS_CODE_PROCESSED);
    const getDisabledEdit = (rows?: any[]) => {
        if (rows?.length !== 1) return true;

        const { source } = rows[0];
        if (source !== 'Встановлено під час ПЗНП' && queryType === QueryType.alienAccounts) return true;
        return false;
    };
    const disabledEdit = getDisabledEdit(selectedRows);

    const handleOpenModal = (isUpdate: boolean) => {
        if (isUpdate && disabledEdit && queryType === QueryType.alienAccounts)
            return message.warning('Редагування можливе лише для записів, які були встановлені під час ПЗНП');
        setIsUpdate(isUpdate);
        openModal();
    };

    const fetchReport = () => {
        if (!reportType) return;
        setLoading(true);
        const dataSourceDto = dto ? dto(currentDataSource) : currentDataSource;
        const body = { data: dataSourceDto, ...clientInfo };
        reportFetch(reportType, null, null, null, body, token, user?.id).finally(() => setLoading(false));
    };

    const handlePrintClick = () => {
        if (isFiltersApplied || isSorterApplied) {
            Modal.confirm({
                title: 'Підтвердження',
                content: 'Увага! Присутнє сортування та фільтрація. Виконати формування звіту?',
                onOk: () => fetchReport(),
                okText: 'Так',
                cancelText: 'Ні',
                style: { top: '20%' },
            });
        } else {
            fetchReport();
        }
    };

    const { triggerDelete, loading: isLoadingDelete } = useDeleteQuery();

    const handleDelete = () => {
        triggerDelete(
            queryType,
            selectedRows?.map((row) => row.id),
            () => {
                clearSelection && clearSelection();
                if (queryType === QueryType.cddMeasures && alertId)
                    dispatch(alertsApi.util.invalidateTags([{ type: Tags.alert, id: alertId }]));
            },
        );
    };

    const handleDeleteClick = () => {
        if (
            queryType === QueryType.alienAccounts &&
            selectedRows?.some((row) => row.source !== 'Встановлено під час ПЗНП')
        )
            return message.warning('Видалення можливе лише для записів, які були встановлені під час ПЗНП');

        Modal.confirm({
            title: 'Підтвердження',
            content: 'Увага! Видалити запис?',
            onOk: () => handleDelete(),
            okText: 'Так',
            cancelText: 'Ні',
            style: { top: '20%' },
        });
    };

    return (
        <Row justify={'end'} style={{ margin: 4 }}>
            <Space>
                {clearFilters && <Button onClick={clearFilters}>Скинути фільтри</Button>}
                {reportType && (
                    <Button onClick={handlePrintClick} loading={loading}>
                        Сформувати звіт
                    </Button>
                )}
                {isEditable && (
                    <>
                        <CustomModal destroyOnClose title={'Додати запис'} open={isModalOpen} onCancel={closeModal}>
                            <EditableTableModal
                                formItemsProps={formItemsProps}
                                selectedRows={selectedRows}
                                isUpdate={isUpdate}
                                closeModal={closeModal}
                                queryType={queryType}
                                clearSelection={clearSelection}
                                alertId={alertId}
                            />
                        </CustomModal>
                        <Button type="primary" onClick={() => handleOpenModal(false)} disabled={disabledActions}>
                            Додати
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => handleOpenModal(true)}
                            disabled={disabledActions || disabledEdit}>
                            Редагувати
                        </Button>
                        <Button
                            type="primary"
                            danger
                            loading={isLoadingDelete}
                            onClick={handleDeleteClick}
                            disabled={disabledActions}>
                            Видалити
                        </Button>
                    </>
                )}
            </Space>
        </Row>
    );
};

export default ActionsBar;
